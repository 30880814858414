import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useNotify from '../../hooks/useNotify';
import { doSwaggerCall } from '../../hooks/useApi';
import PageWithTitle from '../../components/atom/PageWithTitle';
import CommonButton from '../../components/atom/CommonButton';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constants';
import GenericTable from '../../components/atom/GenericTable';
import { PAYMENTS_TABLE_COLUMNS } from '../../helpers/tableColumnLists';
import { handleNavigateBack } from '../../helpers/handleDetailsPageFunctions';

const NAVIGATE_BACK_URL = '/users';

const defaultParkingValue = {
    refreshStatus: '',
    user_id: '',
    status: '',
    entryTime: '-',
    exitTime: '-',
    amount: '',
    freeTimeAfterEntry: '',
    freeTimeAfterPayment: '',
    paid: '',
};

function ParkingDetailsPage() {
    const { parkingId } = useParams();
    const [parkingLoading, setParkingLoading] = useState(true);
    const [paymentsLoading, setPaymentsLoading] = useState(true);
    const navigate = useNavigate();
    const [parking, setParking] = useState(null);
    const [payments, setPayments] = useState([]);
    const { notifyError } = useNotify();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const location = useLocation();
    const hasHistory = location.state?.history && location.state?.history.length > 0;

    const loadParking = useCallback(() => {
        setParkingLoading(true);
        doSwaggerCall('ParkingTickets', 'getParkingTicket', { id: parkingId })
            .then((res) => {
                setParking({
                    refreshStatus: res.refreshStatus,
                    id: res.ticket.id,
                    user_id: res.ticket.user_id,
                    status: res.ticket.status,
                    entryTime: res.ticket.entryTime,
                    exitTime: res.ticket.exitTime,
                    amount: res.ticket.amount,
                    freeTimeAfterEntry: res.ticket.freeTimeAfterEntry,
                    freeTimeAfterPayment: res.ticket.freeTimeAfterPayment,
                    paid: res.ticket.paid,
                });
                setParkingLoading(false);
            })
            .catch((err) => {
                setParkingLoading(false);
                notifyError(err);
                navigate(NAVIGATE_BACK_URL);
            });
    }, [parkingId]);

    const loadPayments = useCallback(() => {
        setPaymentsLoading(true);
        doSwaggerCall('Payments', 'getPayments', {
            offset: page * limit,
            limit,
            searchTicketId: parkingId,
        })
            .then((res) => {
                setPayments(res);
                setPaymentsLoading(false);
            })
            .catch((error) => {
                notifyError(error);
                setPaymentsLoading(false);
            });
    }, [page, limit, parkingId]);

    useEffect(() => {
        loadParking();
        loadPayments();
    }, [loadParking, loadPayments]);

    if (parkingLoading || paymentsLoading) {
        return (
            <Grid item xs={12}>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <Formik initialValues={parking || { ...defaultParkingValue }}>
            {({ values }) => (
                <Form>
                    <PageWithTitle title="Parkolás">
                        <Grid item xs={12} sx={{ pb: 1, ml: -9.5 }}>
                            <Grid container spacing={0}>
                                <Grid item xs={1}>
                                    <Grid container justifyContent="flex-end">
                                        {values.refreshStatus === '1' ? (
                                            <Tooltip title="Frissítve">
                                                <IconButton>
                                                    <CheckIcon color="success" />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Nincs frissítve">
                                                <IconButton>
                                                    <CloseIcon color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography>Frissítési sátusz</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Felhasználó ID"
                                value={values.user_id}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Státusz"
                                value={values.status}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Belépés ideje"
                                required
                                type={values.entryTime ? 'datetime-local' : 'text'}
                                value={values.entryTime || '-'}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Kilépés ideje"
                                required
                                type={values.exitTime ? 'datetime-local' : 'text'}
                                value={values.exitTime || '-'}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Befizetés összege (Ft)"
                                required
                                type="number"
                                value={values.amount}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Belépés utáni szabad idő"
                                required
                                value={values.freeTimeAfterEntry}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Fizetés utáni szabad idő"
                                required
                                value={values.freeTimeAfterPayment}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ pb: 4 }}>
                            <TextField
                                fullWidth
                                label="Fizetve"
                                required
                                value={values.paid === 1 ? 'Igen' : 'Nem'}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTable
                                rows={payments?.payments}
                                limit={limit}
                                page={page}
                                setParams={setParams}
                                loading={paymentsLoading}
                                total={payments?.total}
                                columns={PAYMENTS_TABLE_COLUMNS}
                                navigationLink="/payments/details/"
                                detailsIcon={<VisibilityIcon />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {hasHistory && (
                                <CommonButton
                                    onClick={() => {
                                        handleNavigateBack(navigate, location, '/parkings');
                                    }}
                                    label="Vissza"
                                    buttonType="text"
                                />
                            )}
                        </Grid>
                    </PageWithTitle>
                </Form>
            )}
        </Formik>
    );
}

export default ParkingDetailsPage;
