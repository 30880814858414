// How many rows to show per page by default
export const TABLE_ROW_COUNT_DEFAULT = 25;
// Row count options for tables
export const TABLE_ROW_COUNT_OPTIONS = [25, 50, 100];
// Table pagination label
export const LABEL_ROWS_PER_PAGE = 'Sorok száma oldalanként:';

// Audit log
export const AUDIT_LOG_DESCRIPTIONS = Object.freeze({
    login_success: 'Felhasználó belépett',
    login_failed: 'Felhasználó belépési kísérlet',
    login_request: 'Felhasználó belépési kísérlet',
    registration: 'Felhasználó regisztrált',
    refresh_data_start: 'Adatok frissítése elkezdődött',
    refresh_data_end: 'Adatok frissítése befejeződött',
    favorites_add: 'Kedvenc hozzáadva',
    favorites_remove: 'Kedvenc eltávolítva',
    user_update: 'Felhasználó frissítve',
    parking_ticket_error: 'Parkolójegy hiba',
});

// Roles
export const ROLES = Object.freeze({
    ADMIN: 'admin',
    USER: 'user',
});

// Languages
export const LANG = ['hu', 'en'];
