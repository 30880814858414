// eslint-disable-next-line import/prefer-default-export
export const handleNavigateBack = (navigate, location, basePath) => {
    const prevPaths = [];
    if (location.state?.history) {
        prevPaths.push(...location.state.history);
    }
    prevPaths.pop();

    const lastPath = location.state?.history[location.state.history.length - 1];

    navigate(lastPath || basePath, {
        state: {
            history: prevPaths,
        },
    });
};
