import { Grid, TextField } from '@mui/material';
import { FastField } from 'formik';
import React from 'react';

const SearchInput = ({ name, helperText, xs, sm, onChangeProps, ...props }) => (
    <Grid item xs={xs ?? 12} sm={sm ?? 6}>
        <FastField name={name}>
            {({ field, meta }) => (
                <TextField
                    {...field}
                    {...props}
                    error={meta.error && meta.touched}
                    helperText={(meta.touched && meta.error) || helperText}
                    onChange={(ev) => {
                        onChangeProps.setFieldValue(onChangeProps.fieldId, ev.target.value);
                        const delayDebounceFn = setTimeout(() => {
                            onChangeProps.setSearchStr(ev.target.value);
                        }, 1000);
                        return () => clearTimeout(delayDebounceFn);
                    }}
                    fullWidth
                />
            )}
        </FastField>
    </Grid>
);

export default SearchInput;
