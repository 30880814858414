import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormGroup,
    FormControlLabel,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import { LANG, ROLES } from '../../../helpers/constants';
import CommonButton from '../../../components/atom/CommonButton';
import UploadQRCode from './UploadQRCode';

const NAVIGATE_BACK_URL = '/users';

const BasicSettings = ({ values, setFieldValue, isSubmitting }) => {
    const navigate = useNavigate();
    return (
        <Grid item xs={6}>
            <BlockWithTitle title="Felhasználó adatai">
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email cím"
                        value={values.email}
                        onChange={(ev) => setFieldValue('email', ev.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Vezetéknév"
                        value={values.lastname}
                        onChange={(ev) => setFieldValue('lastname', ev.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Keresztnév"
                        value={values.firstname}
                        onChange={(ev) => setFieldValue('firstname', ev.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Regisztráció ideje"
                        defaultValue={values.registration.replaceAll('-', '. ')}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Születési dátum"
                        type="date"
                        value={values.birthdate || '-'}
                        onChange={(ev) => setFieldValue('birthdate', ev.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="lang">Nyelv</InputLabel>
                        <Select
                            fullWidth
                            labelId="lang"
                            value={values.lang}
                            size="small"
                            label="Nyelv"
                            onChange={(ev) => {
                                setFieldValue('lang', ev.target.value);
                            }}
                        >
                            {LANG.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="role">Jogosultság</InputLabel>
                        <Select
                            fullWidth
                            labelId="role"
                            value={values.role}
                            size="small"
                            label="Jogosultság"
                            onChange={(ev) => {
                                setFieldValue('role', ev.target.value);
                            }}
                        >
                            {Object.values(ROLES).map((role) => (
                                <MenuItem key={role} value={role}>
                                    {role}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <UploadQRCode vipurl={values.vipurl} setFieldValue={setFieldValue} />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.lurdylifemember}
                                    onChange={(ev) =>
                                        setFieldValue('lurdylifemember', ev.target.checked)
                                    }
                                />
                            }
                            label="Lurdy life tag"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CommonButton
                        fullWidth
                        onClick={() => navigate(NAVIGATE_BACK_URL)}
                        label="Mégsem"
                        buttonType="text"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CommonButton
                        fullWidth
                        disabled={isSubmitting}
                        type="submit"
                        label="Mentés"
                        buttonType="primary"
                    />
                </Grid>
            </BlockWithTitle>
        </Grid>
    );
};

export default BasicSettings;
