import { Grid, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constants';
import { PARKINGS_TABLE_COLUMNS } from '../../../helpers/tableColumnLists';
import FormikInput from '../../../components/atom/FormikInput';
import GenericTable from '../../../components/atom/GenericTable';

const ParkingDetails = ({ values }) => {
    const { notifyError } = useNotify();
    const [loading, setLoading] = useState(true);
    const [{ limit, page, order, orderBy }, setParams] = useState({
        limit: TABLE_ROW_COUNT_DEFAULT,
        page: 0,
        order: 'asc',
        orderBy: 'status',
    });
    const [parkings, setParkings] = useState([]);
    const [searchStr, setSearchStr] = useState('');

    useEffect(() => {
        setLoading(true);
        doSwaggerCall('ParkingTickets', 'getParkingTickets', {
            offset: page * limit,
            limit,
            searchUserId: values.id,
            search: searchStr,
        })
            .then((res) => {
                setParkings(res);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
                setLoading(false);
            });
    }, [page, limit, setLoading, setParkings, values.id, searchStr]);

    return (
        <Grid item xs={12}>
            <BlockWithTitle title="Parkolások">
                <Form>
                    <Grid container fullWidth sx={{ minWidth: '500px', pt: 2 }}>
                        <Grid item xs={5}>
                            <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <FormikInput
                                    sm={12}
                                    name="search"
                                    label="Keresés jegy ID szerint"
                                    type="text"
                                    onChange={(ev) => {
                                        setSearchStr(ev.target.value);
                                    }}
                                />
                            </DialogContent>
                        </Grid>
                    </Grid>
                </Form>
                <GenericTable
                    rows={parkings?.tickets}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    loading={loading}
                    total={parkings?.total}
                    order={order}
                    orderBy={orderBy}
                    columns={PARKINGS_TABLE_COLUMNS}
                    navigationLink="/parkings/details/"
                />
            </BlockWithTitle>
        </Grid>
    );
};

export default ParkingDetails;
