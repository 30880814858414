import React from 'react';
import './App.css';
import { Box, Container } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFoundPage from './pages/notfound';
import Header from './components/header';
import AuditLogsPage from './pages/auditlogs';
import { useAuth } from './hooks/useAuth';
import { ROLES } from './helpers/constants';
import UsersPage from './pages/users';
import EditUserPage from './pages/users/EditUserPage';
import LoginPage from './pages/login';
import ParkingsPage from './pages/parkings';
import EditParkingPage from './pages/parkings/ParkingDetailsPage';
import PaymentsPage from './pages/payments';
import PaymentDetailsPage from './pages/payments/PaymentDetailsPage';

function PageForAdmin({ children }) {
    const { user } = useAuth();
    // only user that has not a 'user' role can access this page
    if (!user || user.role === ROLES.USER) return <Navigate to="/" />;
    return children;
}

function App() {
    return (
        <Box sx={{ display: 'flex' }}>
            <Header />
            <Box component="main" sx={{ flexGrow: 1, pt: 12 }}>
                <Container maxWidth="xl">
                    <Routes>
                        <Route path="/" exact element={<LoginPage />} />
                        <Route
                            path="/users"
                            element={
                                <PageForAdmin>
                                    <UsersPage />
                                </PageForAdmin>
                            }
                        />
                        <Route
                            path="/users/edit/:userId"
                            element={
                                <PageForAdmin>
                                    <EditUserPage />
                                </PageForAdmin>
                            }
                        />
                        <Route
                            path="/parkings"
                            element={
                                <PageForAdmin>
                                    <ParkingsPage />
                                </PageForAdmin>
                            }
                        />
                        <Route
                            path="/parkings/details/:parkingId"
                            element={
                                <PageForAdmin>
                                    <EditParkingPage />
                                </PageForAdmin>
                            }
                        />
                        <Route
                            path="/auditlogs"
                            element={
                                <PageForAdmin>
                                    <AuditLogsPage />
                                </PageForAdmin>
                            }
                        />
                        <Route
                            path="/payments"
                            element={
                                <PageForAdmin>
                                    <PaymentsPage />
                                </PageForAdmin>
                            }
                        />
                        <Route
                            path="/payments/details/:paymentId"
                            element={
                                <PageForAdmin>
                                    <PaymentDetailsPage />
                                </PageForAdmin>
                            }
                        />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Container>
            </Box>
        </Box>
    );
}

export default App;
