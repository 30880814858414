import { Grid, DialogContent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Form } from 'formik';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import useNotify from '../../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constants';
import { doSwaggerCall } from '../../../hooks/useApi';
import FormikInput from '../../../components/atom/FormikInput';
import GenericTable from '../../../components/atom/GenericTable';
import { PAYMENTS_TABLE_COLUMNS } from '../../../helpers/tableColumnLists';

const PaymentDetails = ({ values }) => {
    const [typeValue, setTypeValue] = useState('Befizetés ID');
    const types = ['Befizetés ID', 'Parkolójegy ID'];
    const { notifyError } = useNotify();
    const [loading, setLoading] = useState(true);
    const [{ limit, page }, setParams] = useState({
        limit: TABLE_ROW_COUNT_DEFAULT,
        page: 0,
    });
    const [payments, setPayments] = useState([]);
    const [searchStr, setSearchStr] = useState('');

    const handleSearchSelectChange = (event) => {
        setTypeValue(event.target.value);
    };

    useEffect(() => {
        setLoading(true);
        let swaggerParams;
        if (searchStr === '') {
            swaggerParams = {
                offset: page * limit,
                limit,
                searchUserId: values.id,
            };
        }
        switch (typeValue) {
            case 'Befizetés ID':
                swaggerParams = {
                    limit,
                    offset: page * limit,
                    search: searchStr,
                    searchUserId: values.id,
                };
                break;
            case 'Parkolójegy ID':
                swaggerParams = {
                    limit,
                    offset: page * limit,
                    searchTicketId: searchStr,
                    searchUserId: values.id,
                };
                break;
            default:
                swaggerParams = {
                    offset: page * limit,
                    limit,
                    search: searchStr,
                    searchUserId: values.id,
                };
                break;
        }

        doSwaggerCall('Payments', 'getPayments', swaggerParams)
            .then((res) => {
                setPayments(res);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
                setLoading(false);
            });
    }, [page, limit, setLoading, setPayments, values.id, searchStr, typeValue]);

    return (
        <Grid item xs={12}>
            <BlockWithTitle title="Fizetések">
                <Form>
                    <Grid container fullWidth sx={{ minWidth: '500px', pl: 2, pt: 2 }}>
                        <Grid item xs={5}>
                            <FormControl fullWidth>
                                <InputLabel id="search-select-label">Keresés típusa</InputLabel>
                                <Select
                                    labelId="search-select-label"
                                    id="search-select"
                                    value={typeValue}
                                    label="search-type"
                                    onChange={handleSearchSelectChange}
                                    name="search-type"
                                    fullWidth
                                >
                                    {types.map((type) => (
                                        <MenuItem value={type} name="searchType">
                                            {type}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <DialogContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                <FormikInput
                                    sm={12}
                                    name="search"
                                    label="Keresés"
                                    type="text"
                                    value={values.search}
                                    onChange={(ev) => {
                                        setSearchStr(ev.target.value);
                                    }}
                                />
                            </DialogContent>
                        </Grid>
                    </Grid>
                </Form>
                <GenericTable
                    rows={payments?.payments}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    loading={loading}
                    total={payments?.total}
                    columns={PAYMENTS_TABLE_COLUMNS}
                    navigationLink="/payments/details/"
                />
            </BlockWithTitle>
        </Grid>
    );
};

export default PaymentDetails;
