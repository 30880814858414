import React, { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import PageWithTitle from '../../components/atom/PageWithTitle';
import { TABLE_ROW_COUNT_DEFAULT, AUDIT_LOG_DESCRIPTIONS } from '../../helpers/constants';
import GenericTable from '../../components/atom/GenericTable';
import { LOGS_TABLE_COLUMNS } from '../../helpers/tableColumnLists';

const AuditLogsPage = () => {
    const { notifyError } = useNotify();
    const [{ limit, page, order, orderBy }, setParams] = useState({
        limit: TABLE_ROW_COUNT_DEFAULT,
        page: 0,
        order: 'asc',
        orderBy: 'user_id',
    });
    const [data, loading, error] = useApi('AuditLog', 'getAuditLogs', {
        limit,
        offset: page * limit,
        order,
        orderBy,
    });
    const [rows, setRows] = useState([]);
    const [showMore, setShowMore] = useState(-1);

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        setRows(
            data?.items.map((log, i) => {
                return {
                    action: log.action,
                    id: log.id,
                    ip: log.ip,
                    user_id: log.user_id || '-',
                    created_at: log.created_at ? new Date(log.created_at).toLocaleString() : '-',
                    log:
                        showMore === i ? (
                            <pre>{JSON.stringify(log.details, null, 2)}</pre>
                        ) : (
                            AUDIT_LOG_DESCRIPTIONS[log.action] || 'Ismeretlen log típus'
                        ),
                    more:
                        showMore !== i ? (
                            <Tooltip title="Részletes verzió megtekintése">
                                <IconButton onClick={() => setShowMore(i)}>
                                    <ExpandMore />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Részletes verzió elrejtése">
                                <IconButton>
                                    <ExpandLess onClick={() => setShowMore(-1)} />
                                </IconButton>
                            </Tooltip>
                        ),
                };
            })
        );
    }, [data?.items, showMore]);

    return (
        <PageWithTitle title="Napló">
            <GenericTable
                rows={rows}
                limit={limit}
                page={page}
                setParams={setParams}
                loading={loading}
                total={false}
                columns={LOGS_TABLE_COLUMNS}
            />
        </PageWithTitle>
    );
};

export default AuditLogsPage;
