/**
 * Access token key name in local storage
 * @type {string}
 */
export const ACCESS_TOKEN_KEY = 'accessToken';

/**
 * Logged in user key name in local storage
 * @type {string}
 */
export const LOGGED_IN_USER_KEY = 'loggedInUser';

/**
 * Backend url for openapi
 * @type {string}
 */
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

/**
 * Test text for test site only
 * @type {number}
 */
export const TEST_LABEL_ENABLED = process.env.REACT_APP_TEST_LABEL_ENABLED === '1';
