import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Grid, FormGroup, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import PageWithTitle from '../../components/atom/PageWithTitle';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constants';
import { PARKINGS_TABLE_COLUMNS } from '../../helpers/tableColumnLists';
import GenericTable from '../../components/atom/GenericTable';
import SearchInput from '../../components/atom/SearchInput';

const ParkingsPage = () => {
    const [searchStr, setSearchStr] = useState('');
    const [searchUserStr, setSearchUserStr] = useState('');
    const [searchIsPaid, setSearchIsPaid] = useState('');
    const [searchStatusStr, setSearchStatusStr] = useState('');
    const [paidCb, setPaidCb] = useState(false);
    const [notPaidCb, setNotPaidCb] = useState(false);
    const { notifyError } = useNotify();

    const [{ limit, page, sortorder, sortby }, setParams] = useState({
        limit: TABLE_ROW_COUNT_DEFAULT,
        page: 0,
        sortorder: 'ASC',
        sortby: 'entryTime',
    });
    const [data, loading, error] = useApi('ParkingTickets', 'getParkingTickets', {
        limit,
        offset: page * limit,
        search: searchStr,
        searchUserId: searchUserStr,
        searchPayed: searchIsPaid,
        searchStatus: searchStatusStr,
        sortorder,
        sortby,
    });

    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        setParams({
            limit: TABLE_ROW_COUNT_DEFAULT,
            page: 0,
            search: searchStr,
            searchUserId: searchUserStr,
            searchPayed: searchIsPaid,
            searchStatus: searchStatusStr,
            sortorder,
            sortby,
        });
    }, [searchStr, searchUserStr, searchIsPaid, searchStatusStr, sortorder, sortby]);

    useEffect(() => {
        if ((!paidCb && !notPaidCb) || (paidCb && notPaidCb)) {
            setSearchIsPaid('');
        }
        if (paidCb && !notPaidCb) {
            setSearchIsPaid(1);
        }
        if (!paidCb && notPaidCb) {
            setSearchIsPaid(0);
        }
    }, [paidCb, notPaidCb]);

    useEffect(() => {
        setRows(
            data?.tickets.map((ticket) => {
                return {
                    amount: ticket.amount,
                    entryTime: ticket.entrytime ? new Date(ticket.entryTime).toLocaleString() : '-',
                    exitTime: ticket.exitTime ? new Date(ticket.exitTime).toLocaleString() : '-',
                    freeTimeAfterEntry: ticket.freeTimeAfterEntry,
                    freeTimeAfterPayment: ticket.freeTimeAfterPayment,
                    id: ticket.id,
                    paid: ticket.paid ? (
                        <IconButton>
                            <CheckIcon color="success" />
                        </IconButton>
                    ) : (
                        <IconButton>
                            <CloseIcon color="error" />
                        </IconButton>
                    ),
                    status: ticket.status,
                    user_id: ticket.user_id || '-',
                };
            })
        );
    }, [data?.tickets]);

    return (
        <PageWithTitle title="Parkolások">
            <Formik
                initialValues={{
                    searchTicketId: '',
                    searchUserId: '',
                    searchStatus: '',
                    typeValue: '',
                }}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <Grid
                            container
                            fullWidth
                            sx={{ minWidth: '500px', pl: 2, pt: 2 }}
                            spacing={2}
                        >
                            <Grid item xs={3}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() => {
                                                    setPaidCb((paid) => !paid);
                                                }}
                                            />
                                        }
                                        label="Fiztve"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() => {
                                                    setNotPaidCb((notPaid) => !notPaid);
                                                }}
                                            />
                                        }
                                        label="Nem fizetve"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={3}>
                                <SearchInput
                                    sm={12}
                                    name="searchTicketId"
                                    label="Parkolójegy ID"
                                    type="text"
                                    onChangeProps={{
                                        fieldId: 'searchTicketId',
                                        setFieldValue,
                                        setSearchStr,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SearchInput
                                    sm={12}
                                    name="searchUserId"
                                    label="Felhasználó ID"
                                    type="text"
                                    onChangeProps={{
                                        fieldId: 'searchUserId',
                                        setFieldValue,
                                        setSearchStr: setSearchUserStr,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SearchInput
                                    sm={12}
                                    name="searchStatus"
                                    label="Státusz"
                                    type="text"
                                    onChangeProps={{
                                        fieldId: 'searchStatus',
                                        setFieldValue,
                                        setSearchStr: setSearchStatusStr,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <GenericTable
                rows={rows}
                limit={limit}
                page={page}
                setParams={setParams}
                loading={loading}
                total={data?.total}
                order={sortorder}
                orderBy={sortby}
                columns={PARKINGS_TABLE_COLUMNS}
                navigationLink="/parkings/details/"
                detailsIcon={<VisibilityIcon />}
            />
        </PageWithTitle>
    );
};

export default ParkingsPage;
