import { DialogActions, DialogContent, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { doSwaggerCall } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import FormikInput from '../../components/atom/FormikInput';
import ComponentTitle from '../../components/atom/ComponentTitle';
import CommonButton from '../../components/atom/CommonButton';
import useNotify from '../../hooks/useNotify';
import { ROLES } from '../../helpers/constants';

const NAVIGATE_TO = '/users';

const TokenForm = ({ onSubmit, setLoginToken }) => {
    return (
        <Formik initialValues={{ secrettoken: '' }} onSubmit={onSubmit}>
            <Form>
                <DialogContent>
                    <Grid container>
                        <Grid item>
                            <ComponentTitle variant="h4" title="Bejelentkezés" />
                        </Grid>
                        <FormikInput
                            sm={12}
                            name="secrettoken"
                            label="token"
                            required
                            type="text"
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <CommonButton
                        label="Mégsem"
                        buttonType="text"
                        onClick={() => {
                            setLoginToken(null);
                        }}
                    />
                    <CommonButton type="submit" label="Bejelentkezés" buttonType="primary" />
                </DialogActions>
            </Form>
        </Formik>
    );
};

const LoginPage = () => {
    const { user, setUser, setAccessToken } = useAuth();
    const [loginToken, setLoginToken] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            return;
        }
        navigate(NAVIGATE_TO);
    }, [user]);

    async function onSubmitWithVerify(secrettoken) {
        const { jwt, role } = await doSwaggerCall(
            'Auth',
            'login',
            {},
            { secrettoken, logintoken: loginToken }
        );
        if (role === ROLES.USER) {
            notifyError('Nincs jogosultságod a bejelentkezéshez.');
            return;
        }
        setAccessToken(jwt);
        const res = await doSwaggerCall('UserData', 'getUserData');
        const name = `${res.firstname} ${res.lastname}`;
        setUser({ role, name, email: res.email });
    }

    const onSubmit = ({ secrettoken }, { setSubmitting }) => {
        setSubmitting(true);
        onSubmitWithVerify(secrettoken)
            .then(() => {
                setSubmitting(false);
                navigate(NAVIGATE_TO);
            })
            .catch((err) => {
                console.log(err);
                notifyError('Hibás kód');
            });
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={8}>
                <Paper sx={{ p: 1 }}>
                    {loginToken ? (
                        <TokenForm onSubmit={onSubmit} setLoginToken={setLoginToken} />
                    ) : (
                        <Formik
                            initialValues={{ email: '' }}
                            onSubmit={({ email }, { setSubmitting }) => {
                                doSwaggerCall('Auth', 'loginRequest', {}, { email })
                                    .then((data) => {
                                        setLoginToken(data.logintoken);
                                        notifySuccess('A kód elküldve az email címre.');
                                        setSubmitting(false);
                                    })
                                    .catch((error) => {
                                        // TODO: fix error message
                                        console.log(error);
                                        notifyError('Hibás email cím');
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <DialogContent>
                                        <Grid container>
                                            <Grid item>
                                                <ComponentTitle
                                                    variant="h4"
                                                    title="Bejelentkezés"
                                                />
                                            </Grid>
                                            <FormikInput
                                                sm={12}
                                                name="email"
                                                label="E-mail cím"
                                                required
                                                type="email"
                                            />
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <CommonButton
                                            type="submit"
                                            label="Bejelentkezési kód kérése"
                                            disabled={isSubmitting}
                                            buttonType="primary"
                                        />
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default LoginPage;
