import React, { useState } from 'react';
import { List, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../hooks/useAuth';

function Profile() {
    const { logoutUser, user } = useAuth();
    const [toggleProfile, setToggleProfile] = useState(null);
    const open = Boolean(toggleProfile);

    const id = open ? 'profile-popover' : undefined;

    const popoverStyle = {
        '& .MuiPaper-root': {
            borderRadius: 1,
            marginTop: 0,
            borderTopWidth: 1,
        },
    };

    return (
        <>
            <Typography
                variant="body2"
                style={{ flexGrow: 1 }}
                onClick={(e) => setToggleProfile(e.currentTarget)}
            >
                {user?.name !== ' ' && user?.name ? user?.name : user?.email}
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={toggleProfile}
                onClose={() => setToggleProfile(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={popoverStyle}
            >
                <List>
                    <MenuItem
                        onClick={() => {
                            logoutUser();
                            setToggleProfile(null);
                        }}
                    >
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>Kijelentkezés</ListItemText>
                    </MenuItem>
                </List>
            </Popover>
        </>
    );
}

export default Profile;
