import React from 'react';
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import SortTableHeader from './SortTableHeader';
import SkeletonTable from './SkeletonTable';
import { TABLE_ROW_COUNT_OPTIONS, LABEL_ROWS_PER_PAGE } from '../../helpers/constants';
import { handlePageChange, handleChangeRowsPerPage } from '../../helpers/handleTableFunctions';

const GenericTable = ({
    rows,
    limit,
    page,
    setParams,
    loading,
    total,
    order,
    orderBy,
    columns,
    navigationLink,
    detailsIcon,
}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleNavigation = (row) => {
        const prevPaths = [];
        if (location.state?.history) {
            prevPaths.push(...location.state.history);
        }
        prevPaths.push(location.pathname);

        navigate(`${navigationLink}${row.id || row.orderref}`, {
            state: {
                history: prevPaths,
            },
        });
    };

    return (
        <Grid item xs={12}>
            <Table>
                <SortTableHeader
                    order={order}
                    orderBy={orderBy}
                    headCells={columns}
                    setParams={setParams}
                />
                <TableBody>
                    {loading && columns.length !== 0 ? (
                        <SkeletonTable rows={limit} cells={columns.length} tableCells={columns} />
                    ) : (
                        rows?.map((row) => (
                            <TableRow key={row.id}>
                                {columns?.map((cell) => {
                                    return (
                                        <TableCell padding="none" sx={{ width: cell.width }}>
                                            {row[cell.id]}
                                        </TableCell>
                                    );
                                })}
                                <TableCell align="right" sx={{ width: '5%' }}>
                                    <Tooltip title="Részletek">
                                        <IconButton
                                            onClick={() => {
                                                handleNavigation(row);
                                            }}
                                        >
                                            {detailsIcon}
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || -1}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
                labelRowsPerPage={LABEL_ROWS_PER_PAGE}
                labelDisplayedRows={({ from, to, count }) => {
                    if (count === -1) {
                        return `${from}-${to}`;
                    }
                    return `${from}-${to} / ${count}`;
                }}
            />
        </Grid>
    );
};

export default GenericTable;
