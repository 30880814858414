import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import useNotify from '../../hooks/useNotify';
import { doSwaggerCall } from '../../hooks/useApi';
import PageWithTitle from '../../components/atom/PageWithTitle';
import BasicSettings from './components/BasicSettings';
import PaymentDetails from './components/PaymentDetails';
import ParkingDetails from './components/ParkingDetails';

const NAVIGATE_BACK_URL = '/users';

const defaultValue = {
    firstname: '',
    lastname: '',
    email: '',
    birthdate: '',
    lang: 'hu',
    role: 'user',
};

const TABS = ['Felhasználó adatai', 'Parkolások', 'Fizetések'];

function EditUserPage() {
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const [activeTab, setActiveTab] = useState(0);

    const loadUser = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Users', 'getUser', { id: userId })
            .then((res) => {
                setUser({
                    id: res.id,
                    firstname: res.firstname,
                    lastname: res.lastname,
                    email: res.email,
                    birthdate: res.birthdate,
                    lang: res.lang,
                    role: res.role,
                    registration: res.created_at,
                    vipurl: res.vipurl,
                    lurdylifemember: res.lurdylifemember,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate(NAVIGATE_BACK_URL);
            });
    }, []);

    useEffect(() => {
        loadUser();
    }, [userId]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            await doSwaggerCall(
                'Users',
                'updateUser',
                { id: userId },
                {
                    firstname: values.firstname.trim(),
                    lastname: values.lastname.trim(),
                    email: values.email.trim(),
                    birthdate: values.birthdate,
                    lang: values.lang,
                    role: values.role,
                    vipurl: values.vipurl,
                    lurdylifemember: values.lurdylifemember,
                }
            );
            loadUser();
            setSubmitting(false);
            navigate(NAVIGATE_BACK_URL);
            notifySuccess('Felhasználó sikeresen módosítva!');
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={user || { ...defaultValue }}>
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <PageWithTitle title="Felhasználó szerkesztése">
                        <Grid container>
                            <Grid item xs={12}>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => setActiveTab(newValue)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    {TABS.map((tab) => (
                                        <Tab key={tab} label={tab} />
                                    ))}
                                </Tabs>
                            </Grid>
                        </Grid>
                        {activeTab === 0 && (
                            <BasicSettings
                                values={values}
                                setFieldValue={setFieldValue}
                                isSubmitting={isSubmitting}
                            />
                        )}
                        {activeTab === 1 && (
                            <ParkingDetails
                                values={values}
                                setFieldValue={setFieldValue}
                                isSubmitting={isSubmitting}
                            />
                        )}
                        {activeTab === 2 && (
                            <PaymentDetails
                                values={values}
                                setFieldValue={setFieldValue}
                                isSubmitting={isSubmitting}
                            />
                        )}
                    </PageWithTitle>
                </Form>
            )}
        </Formik>
    );
}

export default EditUserPage;
