import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ACCESS_TOKEN_KEY, LOGGED_IN_USER_KEY } from '../config';

const AuthContext = React.createContext();

function useLocalStorageBackedState(
    localStorageKey,
    defaultValue = false,
    onExternalStorageChange = null
) {
    const getValueFromLocalStorage = useCallback(() => {
        try {
            const stateFromStorage = JSON.parse(localStorage.getItem(localStorageKey));
            if (stateFromStorage === null) {
                return defaultValue;
            }
            return stateFromStorage;
        } catch (err) {
            console.log(err);
            return defaultValue;
        }
    }, [localStorageKey]);
    const [internalState, setInternalState] = useState(getValueFromLocalStorage);
    const initialStateSetter = useCallback(
        (newValue, fromLocalStorage = false) => {
            setInternalState(newValue);
            // a simple set won't fire a storage call, so we need to fire it manually if that's needed
            if (!fromLocalStorage) {
                if (newValue === false) {
                    localStorage.removeItem(localStorageKey);
                } else {
                    localStorage.setItem(localStorageKey, JSON.stringify(newValue));
                }
            }
        },
        [setInternalState]
    );

    // listen to localstorage changes, so we can invalidate token from anywhere
    useEffect(() => {
        const checkAccessToken = (e) => {
            if (e.key === localStorageKey) {
                const newValue = getValueFromLocalStorage();
                setInternalState(newValue);
                if (onExternalStorageChange !== null) {
                    onExternalStorageChange(newValue);
                }
            }
        };

        // this is needed if you have more windows open
        window.addEventListener('storage', checkAccessToken);
        return () => {
            window.removeEventListener('storage', checkAccessToken);
        };
    }, [setInternalState, onExternalStorageChange]);

    return [internalState, initialStateSetter];
}

export function AuthContextProvider({ children }) {
    const [user, setUser] = useLocalStorageBackedState(LOGGED_IN_USER_KEY);
    const [_accessToken, setAccessToken] = useLocalStorageBackedState(
        ACCESS_TOKEN_KEY,
        false,
        (newToken) => {
            if (newToken === false) {
                setUser(false);
            }
        }
    );

    const logoutUser = useCallback(() => {
        setUser(false);
        setAccessToken(false);
    }, [setUser]);

    const contextValue = useMemo(
        () => ({
            user,
            setUser,
            logoutUser,
            setAccessToken,
        }),
        [user, setUser, logoutUser, setAccessToken]
    );

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

AuthContext.displayName = 'AuthContext';

/**
 * Auth context
 * @returns {{logoutUser: logoutUser, setUser: setUser, setAccessToken: setAccessToken, user: {}}}
 */
export function useAuth() {
    return useContext(AuthContext);
}
