import { Grid, FormControl, InputLabel, Input, Button, InputAdornment } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import jsQR from 'jsqr-es6';
import useNotify from '../../../hooks/useNotify';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const UploadQRCode = ({ vipurl, setFieldValue }) => {
    const { notifyError } = useNotify();
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        const r = new FileReader();

        r.readAsDataURL(file);
        r.onload = () => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                const imgData = ctx.getImageData(0, 0, img.width, img.height);

                try {
                    const code = jsQR(imgData.data, 300, 300);
                    setFieldValue('vipurl', code.data);
                } catch (error) {
                    notifyError(error);
                }
            };
            img.src = r.result;
        };
    };

    return (
        <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="vipurl">Dolgozói kedvezmény QR</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type="text"
                    labelId="vipurl"
                    endAdornment={
                        <InputAdornment position="end">
                            <Button
                                sx={{
                                    width: '40px',
                                    height: '40px',
                                    minWidth: 0,
                                    paddingRight: 3,
                                }}
                                component="label"
                                variant="text"
                                endIcon={<QrCode2Icon />}
                            >
                                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                            </Button>
                        </InputAdornment>
                    }
                    value={vipurl}
                    onChange={(e) => {
                        setFieldValue('vipurl', e.target.value);
                    }}
                />
            </FormControl>
        </Grid>
    );
};

export default UploadQRCode;
