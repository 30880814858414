import { IconButton, Toolbar, Grid } from '@mui/material';
import { Menu, ChevronLeft } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, DrawerHeader, AppBar } from './Drawer';
import SidebarMenu from './SidebarMenu';
import { useAuth } from '../../hooks/useAuth';
import Profile from './Profile';
import TestPageWarningLabel from './TestPageWarningLabel';
import { TEST_LABEL_ENABLED } from '../../config';

const Header = () => {
    const { user } = useAuth();
    const [open, setOpen] = useState(true);
    const handleDrawerOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleDrawerClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    useEffect(() => {
        setOpen(!!user);
    }, [user]);

    return (
        <>
            {TEST_LABEL_ENABLED && <TestPageWarningLabel />}
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    {user ? (
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                mr: 5,
                            }}
                            style={{
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <Menu />
                        </IconButton>
                    ) : null}

                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item style={{ flex: '1 1 0' }}>
                            <Link to="/users">
                                <img
                                    alt="Lurdy admin"
                                    src="/logo.png"
                                    height="30px"
                                    style={{ paddingTop: '5px', marginBottom: '-3px' }}
                                />
                            </Link>
                        </Grid>
                        {user && (
                            <Grid item>
                                <Profile />
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
            {user ? (
                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeft />
                        </IconButton>
                    </DrawerHeader>
                    <SidebarMenu open={open} />
                </Drawer>
            ) : null}
        </>
    );
};

export default Header;
