import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constants';

function SortTableHeader({ order, orderBy, headCells, setParams }) {
    const handleClick = (property) => () => {
        if (property.sortable) {
            setParams({
                limit: TABLE_ROW_COUNT_DEFAULT,
                page: 0,
                sortorder: order === 'ASC' ? 'DESC' : 'ASC',
                sortby: property.id,
            });
        }
    };
    return (
        <TableHead>
            <TableRow>
                {headCells?.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order.toLowerCase() : 'asc'}
                            onClick={handleClick(headCell)}
                            disabled={!headCell.sortable}
                            sx={{ width: headCell.width }}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default SortTableHeader;
