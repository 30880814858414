import React from 'react';
import { TableCell, Skeleton, TableRow } from '@mui/material';

const SkeletonTable = ({ rows, cells, tableCells }) => {
    const skeletonRows = Array(rows).fill('');
    const skeletonCells = Array(cells).fill('');
    return skeletonRows.map((row, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={`${row}-${idx}`}>
            {skeletonCells.map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={i} sx={{ width: tableCells[i].width }}>
                    <Skeleton animation="pulse" height={36.5} width="100%" />
                </TableCell>
            ))}
            <TableCell key={row.id} sx={{ width: '5%' }}>
                <Skeleton animation="pulse" height={36.5} width="100%" />
            </TableCell>
        </TableRow>
    ));
};

export default SkeletonTable;
